<template>
    <div>
        <slot />
        <b-popover :target="idPopover" triggers="hover" placement="auto" custom-class="box__preview--common">
            <template #title>{{ title }}</template>
            <div class="form-group row mb-0">
                <label class="mw--form" for="prizeLessStatus">
                    <span class="box__preview--common--title">{{ this.$t('pageCampaign.tabPrize.prizeLessStatus') }}</span>
                </label>
                <div class="overflow-hidden">
                    <p class="box__preview--common--content">{{ formPreview.prize_less_status?.value ?? '' }}</p>
                </div>
            </div>

            <div class="form-group row mb-0">
                <label class="mw--form" for="reconfirmationDate">
                    <span class="box__preview--common--title">{{ this.$t('pageCampaign.tabPrize.reconfirmationDate') }}</span>
                </label>
                <div class="overflow-hidden">
                    <p class="box__preview--common--content">{{ formPreview.reconfirmation_date }}</p>
                </div>
            </div>

            <div class="form-group row mb-0">
                <label class="mw--form" for="prizeResponseInstructions">
                    <span class="box__preview--common--title">{{ this.$t('pageCampaign.tabPrize.prizeResponseInstructions') }}</span>
                </label>
                <div class="overflow-hidden">
                    <p class="box__preview--common--content">{{ formPreview.prize_refund_instructions }}</p>
                </div>
            </div>

            <div class="form-group row mb-0">
                <label class="mw--form" for="storageFee">
                    <span class="box__preview--common--title">{{ this.$t('pageCampaign.tabPrize.storageFee') }}</span></label
                >
                <div class="overflow-hidden">
                    <p class="box__preview--common--content">{{ formPreview.preserve_fee }}</p>
                </div>
            </div>

            <div class="form-group row mb-0">
                <label class="mw--form" for="storageFeeOccurrenceDate">
                    <span class="box__preview--common--title">{{ this.$t('pageCampaign.tabPrize.storageFeeOccurrenceDate') }}</span></label
                >
                <div class="overflow-hidden">
                    <p class="box__preview--common--content">{{ formPreview.date_of_preserve }}</p>
                </div>
            </div>

            <div class="form-group row mb-0">
                <label class="mw--form" for="remarks">
                    <span class="box__preview--common--title">{{ this.$t('pageCampaign.tabPrize.remarks') }}</span></label
                >
                <div class="overflow-hidden">
                    <p class="box__preview--common--content">{{ formPreview.reference }}</p>
                </div>
            </div>
        </b-popover>
    </div>
</template>

<script>
export default {
    name: 'PopoverPreviewCompatible',
    components: {},
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        dataPreview: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            idPopover: this.id,
            formPreview: this.dataPreview
        };
    },
    watch: {
        dataPreview: {
            handler(newDataPreview) {
                this.formPreview = newDataPreview;
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss"></style>
