<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3">エンドクライアント</label>
            <div class="col-sm-9">
                <div class="d-flex">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>エンドクライアント名</th>
                                <th>担当</th>
                                <th>部署</th>
                                <th>メールアドレス</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, k) in dataForm.clients" :key="k + 'fc' + item.client_id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.client_pic_name }}</td>
                                <td>{{ item.client_pic_department }}</td>
                                <td>{{ item.client_pic_email }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" class="btn btn-sm btn-success ml-5" style="white-space: nowrap; opacity: 0">新規登録</button>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">発注元</label>
            <div class="col-sm-9">
                <div class="d-flex">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>代理店名</th>
                                <th>担当</th>
                                <th>部署</th>
                                <th>メールアドレス</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, k) in dataForm.agencies" :key="k + 'fa' + item.agency_id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.agency_pic_name }}</td>
                                <td>{{ item.agency_pic_department }}</td>
                                <td>{{ item.agency_pic_email }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" class="btn btn-sm btn-success ml-5" style="white-space: nowrap; opacity: 0">新規登録</button>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">代理店</label>
            <div class="col-sm-9">
                <div>
                    {{ dataForm.source_data ? (dataForm.source_data.type == 1 ? 'エンドクライアント' : '代理店') : '' }}
                </div>
                <div>{{ dataForm.source_data ? dataForm.source_data.name : '' }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableClients',
    props: {
        dataForm: Object,
        default: () => {}
    }
};
</script>

<style lang="scss" scoped></style>
