<template>
    <div>
        <div class="table-responsive">
            <table class="table mb-0 table-bordered">
                <thead>
                    <tr>
                        <th>権限</th>
                        <th>日時</th>
                        <th>名称</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in dataHistory" :key="'c' + item.id">
                        <td>{{ item.user && item.user.role ? $t(item.user.role.name) : '' }}</td>

                        <td>{{ getDate(item.created_at) }}</td>
                        <td>{{ item.user ? item.user.name : '' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { getCurrentDateTime } from '../../index';

export default {
    name: 'TabHistories',
    props: {
        dataHistory: Array,
        default: () => []
    },
    methods: {
        getDate(createdAt) {
            return getCurrentDateTime(createdAt);
        }
    }
};
</script>

<style lang="scss" scoped></style>
