import i18n from '@/i18n';

let userInfo = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;

const INIT_FROM = {
    room_chat: null,
    configRoomChat: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: true
    },
    errorRoomChat: '',
    source_chat: userInfo ?? '',
    type_send: {
        id: 3,
        name: `${i18n.t('settingPhone.popUpSectionWork.message&&task')}`
    },
    configTypeSend: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: true
    },
    messages: {
        content: '',
        errorContent: '',
        to: [],
        configSendTo: {
            multiple: true,
            trackBy: 'id',
            label: 'name',
            error: false,
            isRemove: true
        },
        errorSendTo: '',
        file: null
    },
    task: {
        content: '',
        errorContent: '',
        to: [],
        configSendTo: {
            multiple: true,
            trackBy: 'id',
            label: 'name',
            error: false,
            isRemove: true
        },
        errorSendTo: '',
        date_task: '',
        time_task: ''
    },
    configTaskDateTime: { minuteStep: 1, placeholderTime: '', errorTimeTask: '', error: false }
};

const OPTIONS_SEND = [
    {
        id: 3,
        name: `${i18n.t('settingPhone.popUpSectionWork.message&&task')}`
    },
    {
        id: 1,
        name: i18n.t('settingPhone.popUpSectionWork.message')
    },
    {
        id: 2,
        name: i18n.t('settingPhone.popUpSectionWork.task')
    }
];

export { INIT_FROM, OPTIONS_SEND };
