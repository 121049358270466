<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3">進捗ステータス</label>
            <div class="col-sm-9">
                <div style="position: relative">
                    {{ progressStatus.text }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">キャンペーン名称</label>
            <div class="col-sm-9">
                {{ dataForm.name }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">ZACID</label>
            <div class="col-sm-9">
                {{ dataForm.zac_id }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3" for="sfid">{{ $t('pageCampaign.sfid') }}</label>
            <div class="col-sm-9">
                <p>{{ dataForm.sfid }}</p>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3" for="campaignEntityId">{{ $t('pageCampaign.campaignEntityId') }}</label>
            <div class="col-sm-9">
                <p>{{ dataForm.campaign_entity_id }}</p>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">キャンペーン種別</label>
            <div class="col-sm-9">
                {{ dataForm.campaign_type_name }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">受注範囲</label>
            <div class="col-sm-9">
                <button type="button" class="btn btn-sm btn-info" @click="handleOpenScopeOrder()">詳細</button>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3 pr-0">キャンペーン開始年月日</label>
            <div class="col-sm-9">
                {{ formatDateToChinese(dataForm.campaign_start_date) }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">キャンペーン終了年月日</label>
            <div class="col-sm-9">
                {{ formatDateToChinese(dataForm.campaign_end_date) }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">事務局開始年月日</label>
            <div class="col-sm-9">
                {{ formatDateToChinese(dataForm.secretariat_start_date) }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">事務局終了年月日</label>
            <div class="col-sm-9">
                {{ formatDateToChinese(dataForm.secretariat_end_date) }}
            </div>
        </div>

        <div v-if="isShowReceiptExpirationDate" class="form-group row">
            <label class="col-sm-3 pr-0">レシート有効期限</label>
            <div class="col-sm-9">
                {{ formatDateToChinese(dataForm.receipt_expiration_date) }}
            </div>
        </div>

        <div class="mb-1 row" v-if="dataForm.campaign_type">
            <label class="col-sm-3">応募種別</label>
            <div class="col-sm-9">
                {{ handleRenderNameCommon(dataMTChild, dataCategories) }}
            </div>
        </div>

        <div
            v-for="(item, k) in dataCategoriesSub"
            :key="k + item.category_id + 's'"
            class="mb-1"
            style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px"
        >
            <div class="row">
                <label class="col-sm-3">{{ renderNameCategory(item) }}</label>
            </div>

            <div v-if="renderNameCategory(item) == 'WEB'" class="form-group row">
                <label class="col-sm-3">応募フォーム</label>
                <div class="col-sm-9">
                    {{ dataForm.application_form }}
                </div>
            </div>

            <div v-if="renderNameCategory(item) == '流通' && dataForm.campaign_type_name == this.$t('pageCampaign.closeCp')" class="form-group row">
                <label class="col-sm-3">流通数</label>
                <div class="col-sm-9">
                    {{ item.extension.number_of_circulation ?? '' }}
                </div>
            </div>

            <div v-if="renderNameCategory(item) == 'その他'" class="form-group row">
                <label class="col-sm-3"></label>
                <div class="col-sm-9" v-html="textLineBreaks(item.extension.other_type_data) ?? ''" />
            </div>

            <div
                v-if="
                    renderNameCategory(item) == 'WEB' ||
                    renderNameCategory(item) == 'ハガキ' ||
                    renderNameCategory(item) == 'Twitter' ||
                    renderNameCategory(item) == 'Instagram' ||
                    renderNameCategory(item) == 'LINE' ||
                    renderNameCategory(item) == 'Tiktok' ||
                    renderNameCategory(item) == '店頭' ||
                    renderNameCategory(item) == 'アプリ'
                "
                class="form-group row"
            >
                <label class="col-sm-3">応募条件①</label>
                <div class="col-sm-9">
                    <div style="position: relative">
                        {{ renderNameSub(item.application_condition_1, item.listCondition1) }}
                    </div>
                </div>
            </div>

            <div
                class="row"
                v-if="item.application_condition_1 && item.application_condition_1?.id && item.application_condition_1?.value !== 'その他'"
            >
                <label class="col-sm-3">応募条件②</label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap">
                        <div>
                            {{ handleRenderNameCondition2(item.listCondition2, item.application_condition_2_id) }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.application_condition_1?.value == 'その他'" class="row">
                <label class="col-sm-3">{{ item.application_condition_1?.value }}</label>
                <div class="col-sm-9">
                    <p v-html="textLineBreaks(item?.extension?.content_other) ?? ''"></p>
                </div>
            </div>

            <div class="row">
                <label class="col-sm-3">応募方法</label>
                <div class="col-sm-9">
                    <p v-html="textLineBreaks(item?.extension?.application_method) ?? ''"></p>
                </div>
            </div>

            <div class="form-group row mt-2" v-if="item.application_condition_2_id.length > 0 && item.checkSNS >= 0">
                <label class="col-sm-3" for="validationCustom05">公式アカウント名</label>
                <div class="col-sm-9">
                    {{ item.extension.offical_name_sns }}
                </div>
            </div>

            <div class="form-group row mt-2" v-if="item.application_condition_2_id.length > 0 && item.checkSNS >= 0">
                <label class="col-sm-3" for="validationCustom05">公式ID</label>
                <div class="col-sm-9">
                    {{ item.extension.offical_id_sns }}
                </div>
            </div>

            <div
                v-if="renderNameCategory(item) == 'Twitter' || renderNameCategory(item) == 'Tiktok' || renderNameCategory(item) == 'Instagram'"
                class="form-group row"
            >
                <label class="col-sm-3">ハッシュタグ</label>
                <div class="col-sm-9">
                    {{ handleRenderHashTag(item.configHashTag.arrayTag) }}
                </div>
            </div>
        </div>

        <div v-if="isShowConditionBuyAndSell">
            <div class="form-group row">
                <label class="col-sm-3">購買条件</label>
                <div class="col-sm-9">
                    {{ handleRenderNameCommon(dataMTPurchase1, dataForm.purchase_conditions) }}
                </div>
            </div>

            <div
                v-for="item in dataForm.purchase_conditions"
                class="form-group row"
                :key="'target' + item"
                style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px"
            >
                <label class="col-sm-3">{{ renderNamePurchase(item) }} 入力 </label>
                <div class="col-sm-9">
                    <div>{{ purchaseConditions[item] ?? '' }}</div>
                </div>
            </div>
        </div>

        <div v-if="isShowReceiptExpirationDate">
            <div class="form-group row">
                <label class="col-sm-3">対象商品カテゴリ</label>
                <div class="col-sm-9">
                    {{ handleRenderNameField(dataForm.target_product_lists) }}
                </div>
            </div>

            <div v-for="item in dataForm.target_product_lists" :key="'target' + item.id">
                <div style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px" class="mb-3">
                    <div class="form-group row">
                        <label class="col-sm-3">{{ item.value }}</label>
                        <div class="col-sm-9">
                            {{ handleRenderNameField(item.children_data) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row mt-3">
            <label class="col-sm-3">告知</label>
            <div class="col-sm-9">
                {{ dataForm.inform }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">LP サイト</label>
            <div class="col-sm-9">
                <a target="_blank" :href="dataForm.url">{{ dataForm.url }}</a>
            </div>
        </div>

        <div v-if="isShowPublicTime" class="form-group row">
            <label class="col-sm-3">公開期間</label>
            <div class="col-sm-9">
                <div class="col-sm-0 mb-2">
                    {{ formatDateToChinese(dataForm.url_publish_time_from) }}
                    {{ dataForm.url_publish_time_to ? '~' : '' }}
                    {{ formatDateToChinese(dataForm.url_publish_time_to) }}
                </div>
                <div class="col-sm-0"></div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">当選者数</label>
            <div class="col-sm-9">
                {{ dataForm.total_number_of_win_prize }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">賞品進呈時期(発送時期・送付時期)</label>
            <div class="col-sm-9">
                {{ dataForm.prize_shipping_period }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">お問い合わせ事務局名</label>
            <div class="col-sm-9">
                <p v-html="textLineBreaks(dataForm.inquiry_office)"></p>
            </div>
        </div>

        <div v-for="item in dataContactInfo" :key="item.id + 's'">
            <div v-if="dataForm.contact_informations.includes(item.id)" class="form-group row">
                <label class="col-sm-3">{{ item.subText }}</label>
                <div class="col-sm-9">
                    <div v-if="item.child && item.child.length > 0">
                        <div v-for="(oChild, idoChild) in item.child" :key="idoChild" class="col-sm-9">
                            <div v-if="dataForm.contact_informations.includes(oChild.id)">
                                <div class="form-group row">
                                    <label class="mr-3">{{ oChild.text }}</label>
                                    <div>
                                        {{ contactInformations[oChild.id] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex align-items-center">
                        <div
                            v-if="item.subText == 'TEL入力'"
                            :class="dataForm.phone_setting?.disable_edit_field?.field_pre_order_phone_numbers ? 'mr-3' : ''"
                        >
                            {{ dataForm.phone_setting?.disable_edit_field?.field_pre_order_phone_numbers ? contactInformations[item.id] : '' }}
                        </div>
                        <div v-else>
                            {{ contactInformations[item.id] }}
                        </div>
                        <button
                            v-if="item.subText == 'TEL入力'"
                            type="button"
                            class="btn btn-sm btn-primary"
                            @click="
                                () => {
                                    $router.push({
                                        path: '/setting-phone',
                                        query: {
                                            id: $route.query.id
                                        }
                                    });
                                }
                            "
                        >
                            電話工事
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="form-group row">
                <label class="col-sm-3">検索キーワード</label>
                <div class="col-sm-9">
                    {{ handleRenderNameField(dataForm.keywords, '検索キーワード') }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3">メモ欄</label>
                <div class="col-sm-9">
                    <p v-html="textLineBreaks(dataForm.memo)"></p>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3" for="ChatworkRoom">Chatwork Room</label>
            <div class="col-sm-9 pl-0">
                <p v-html="handleRenderChatRoom(dataForm.chatwork_rooms)" />
            </div>
        </div>

        <div class="mb-3">
            <div class="form-group row">
                <label class="col-sm-3" for="ipInformationItem">{{ $t('pageCampaign.ipInformationItem') }}</label>
                <div class="col-sm-9">
                    <p>{{ handleGetNameIP(dataForm.information_ips) }}</p>
                    <p v-if="dataForm?.information_ips?.length > 0">
                        {{ handleGetNameIP(dataForm?.information_ips[0]?.children_data) }}
                    </p>
                </div>
            </div>

            <div v-for="itemIP in dataForm.information_ips" :key="itemIP.id">
                <div v-if="itemIP.children_data.length > 0">
                    <div v-for="itemChildIP in itemIP.children_data" :key="itemChildIP.id">
                        <div class="mb-1" style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px">
                            <div class="row">
                                <label class="col-sm-3">{{ itemChildIP.value }}</label>
                                <div class="col-sm-9">
                                    <p v-html="textLineBreaks(itemChildIP?.note) ?? ''"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <div class="form-group row">
                <label class="col-sm-3" for="campaignSponsorInformation">{{ $t('pageCampaign.campaignSponsorInformation') }}</label>
                <div class="col-sm-9">
                    <p>{{ handleGetNameIP(dataForm.sponsor_informations) }}</p>
                </div>
            </div>

            <div v-for="itemSponsor in dataForm.sponsor_informations" :key="itemSponsor.id">
                <div style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px">
                    <div v-if="itemSponsor.value == 'メーカー単体キャンペーン'">
                        <div class="form-group row">
                            <label class="col-sm-3" for="ChatworkRoom">{{ $t('pageCampaign.manufacturerName') }}</label>
                            <div class="col-sm-9">
                                <p>{{ itemSponsor?.note?.manufacturer_name }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="itemSponsor.value == '流通単体キャンペーン'">
                        <div class="form-group row">
                            <label class="col-sm-3" for="ChatworkRoom">{{ $t('pageCampaign.distributionName') }}</label>
                            <div class="col-sm-9">
                                <p>{{ itemSponsor?.note?.distribution_name }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="itemSponsor.value == 'タイアップキャンペーン'">
                        <div class="form-group row">
                            <label class="col-sm-3" for="ChatworkRoom">{{ $t('pageCampaign.circulationNumber') }}</label>
                            <div class="col-sm-9">
                                <p>{{ itemSponsor?.note?.circulation_number }}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3" for="ChatworkRoom">{{ $t('pageCampaign.distributionName') }}</label>
                            <div class="col-sm-9">
                                <p v-html="textLineBreaks(itemSponsor?.note?.circulation_distribution) ?? ''" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3" for="ChatworkRoom">{{ $t('pageCampaign.tie-upManufacturers') }}</label>
                            <div class="col-sm-9">
                                <p>{{ itemSponsor?.note?.up_manufacturers }}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3" for="ChatworkRoom">{{ $t('pageCampaign.manufacturerName') }}</label>
                            <div class="col-sm-9">
                                <p v-html="textLineBreaks(itemSponsor?.note?.up_manufacturers_name) ?? ''" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3" for="associated_campaign_ID">{{ $t('pageCampaign.associated_campaign_ID') }}</label>
            <div class="col-sm-9">
                <a target="_blank" :href="`${$route.path}?id=${dataForm.associated_campaign?.id ?? ''}`">
                    {{ dataForm.associated_campaign?.id ?? '' }}</a
                >
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3" for="projectFolder">{{ $t('pageCampaign.projectFolder') }}</label>
            <div class="col-sm-9">
                <p>{{ dataForm.folder_project }}</p>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3" for="quoteFolder">{{ $t('pageCampaign.quoteFolder') }}</label>
            <div class="col-sm-9">
                <p>{{ dataForm.folder_quotation }}</p>
            </div>
        </div>

        <div class="form-group row">
            <button
                class="btn-sm btn btn-link mr-2"
                type="button"
                style="cursor: default"
                v-if="dataForm.is_approved == 0 && !checkPermiss('campaign.edit')"
            >
                承認待ち
            </button>
            <button
                class="btn-sm btn btn-link mr-2"
                type="button"
                style="cursor: default"
                v-if="dataForm.is_approved != 0 && dataForm.is_locked != 1"
            >
                承認済み
            </button>
            <button
                class="btn-sm btn btn-primary mr-2"
                type="button"
                @click="approveCampaigns()"
                v-if="dataForm.is_approved == 0 && checkPermiss('campaign.approve')"
            >
                承認
            </button>
            <button
                class="btn-sm btn btn-secondary mr-2"
                type="button"
                @click="unLockCampaigns()"
                v-if="dataForm.is_locked == 1 && checkPermiss('campaign.unlock')"
            >
                ロック解除
            </button>
            <button
                class="btn-sm btn btn-info mr-2"
                type="button"
                @click="copyCampaigns()"
                v-if="dataForm.is_approved != 0 && checkPermiss('campaign.copy')"
            >
                複製
            </button>
            <button
                class="btn-sm btn btn-primary mr-2"
                type="button"
                @click="lockCampaigns()"
                v-if="dataForm.is_locked != 1 && dataForm.is_approved != 0 && checkPermiss('campaign.lock')"
            >
                ロック
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { renderLineBreaks, convertTagsToHashTags } from '@/utils/format';
import { checkPermission } from '@/state/helpers';

export default {
    name: 'TabBasic',
    props: {
        dataForm: {
            type: Object,
            default: () => {}
        },
        progressStatus: {
            type: Object,
            default: () => {}
        },
        contactInformations: {
            type: Object,
            default: () => {}
        },
        purchaseConditions: {
            type: Object,
            default: () => {}
        },
        isShowReceiptExpirationDate: {
            type: Boolean,
            default: () => false
        },
        isShowConditionBuyAndSell: {
            type: Boolean,
            default: () => false
        },
        isShowPublicTime: {
            type: Boolean,
            default: () => false
        },
        dataMTChild: {
            type: Array,
            default: () => []
        },
        dataCategories: {
            type: Array,
            default: () => []
        },
        dataCategoriesSub: {
            type: Array,
            default: () => []
        },
        dataMTPurchase1: {
            type: Array,
            default: () => []
        },
        dataContactInfo: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        formatDateToChinese(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = moment(time).format('HH:mm') ?? '';

                if (time.includes(' ')) {
                    return `${year} 年 ${month} 月 ${day} 日 ${hours}`;
                } else {
                    return `${year} 年 ${month} 月 ${day} 日`;
                }
            }
            return '';
        },

        handleRenderNameCommon(param1, param2) {
            const resultText = param1?.filter((item) => param2.includes(item.id));
            if (!resultText || resultText.length <= 0) return '';
            return resultText.map((rs) => rs.value).join('、');
        },

        renderNameCategory(item) {
            let index = this.dataMTChild.findIndex((x) => {
                return x.id == item.category_id;
            });
            if (index >= 0) {
                return this.dataMTChild[index].value;
            }
        },

        renderNameSub(object, data) {
            let index = data.findIndex((x) => {
                return object && x.id == object?.id;
            });
            if (index >= 0) {
                return data[index].value;
            }
            return '';
        },

        handleRenderNameCondition2(arrayCondition2, applicationCondition2Id) {
            let arrayMapId = [];
            if (arrayCondition2.length > 0 && applicationCondition2Id) {
                arrayMapId = arrayCondition2.map((item) => item.id);
                let listConditionTM = applicationCondition2Id.filter((x) => arrayMapId.find((y) => y == x));
                let textArr = arrayCondition2
                    .map((item) => {
                        let index = listConditionTM.findIndex((x) => x == item.id);
                        if (index >= 0) {
                            return item.value;
                        }
                    })
                    .filter((arr) => arr !== undefined);
                return textArr.join('、');
            }
            return '';
        },

        textLineBreaks(text) {
            return renderLineBreaks(text);
        },

        handleRenderHashTag(hashTag) {
            if (hashTag && hashTag.length > 0) {
                return convertTagsToHashTags(hashTag).join('、');
            }
            return '';
        },
        renderNamePurchase(i) {
            let index = this.dataMTPurchase1.findIndex((x) => {
                return x.id == i;
            });
            if (index >= 0) {
                return this.dataMTPurchase1[index].value;
            }
            return '';
        },

        handleRenderNameField(param1, field) {
            if (!param1 || param1.length <= 0) return '';
            if (field == '検索キーワード') {
                return param1.join('、');
            }
            return param1.map((rs) => rs.value).join('、');
        },

        handleRenderChatRoom(roomChat) {
            if (!roomChat || roomChat.length <= 0) return '';
            return roomChat
                .map((item) => {
                    return `${item.room_name} - ${item.room_id}`;
                })
                .join('、');
        },

        handleGetNameIP(namesIP) {
            if (namesIP && namesIP.length > 0) {
                return namesIP.map((item) => item.value).join('、');
            }
            return '';
        },

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        handleOpenScopeOrder() {
            this.$emit('openScope');
        },

        approveCampaigns() {
            this.$emit('approveCampaign');
        },

        lockCampaigns() {
            this.$emit('lockCampaign');
        },

        unLockCampaigns() {
            this.$emit('unLockCampaign');
        },

        copyCampaigns() {
            this.$emit('copyCampaign');
        }
    }
};
</script>

<style lang="scss"></style>
