<template>
    <div>
        <ModalCommon :id="idTable" :config="configModalDetailsReturns" @close="handleCloseDetailReturn()" :isView="true">
            <div class="table-responsive" style="height: 620px; overflow-y: auto; outline: none">
                <table class="table table-bordered custom__table--sticky">
                    <thead>
                        <tr>
                            <th v-for="(item, k) in configTableDetailReturn.headers" :key="k">
                                <div class="d-flex align-items-center" style="min-width: max-content; gap: 5px">
                                    {{ item.label }}
                                    <span v-if="item.required" class="text-danger">*</span>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, idx) in listDetailReturns" :key="idx">
                            <td>
                                <div style="min-width: 150px">
                                    {{ item.return_date }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    {{ item.shipping_company }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    {{ item.number }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 200px">
                                    {{ item.reason }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    {{ item.province }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    {{ item.shipping_return_fee }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 200px">
                                    {{ item.return_status?.value ?? '' }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    {{ item.reconfirmation_schedule }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    {{ item.instruction }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    {{ item.storage_code }}
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 200px">
                                    {{ item.note }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import { CONFIG_TABLE_DETAIL_RETURN, CONFIG_MODAL_DETAIL_RETURN } from '@/components/Campaign/TabPrize/constans';
import ModalCommon from '@/components/Modal/main.vue';

export default {
    name: 'ModalDetailsReturns',
    components: {
        ModalCommon
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        dataDetailReturns: {
            type: Array,
            default: () => []
        },
        returnPrizeItemsCount: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            idTable: this.id,
            listDetailReturns: this.dataDetailReturns,
            configTableDetailReturn: CONFIG_TABLE_DETAIL_RETURN,
            configModalDetailsReturns: CONFIG_MODAL_DETAIL_RETURN
        };
    },
    methods: {
        handleCloseDetailReturn() {
            this.$emit('update:isShow', false);
        }
    },
    watch: {
        dataDetailReturns: {
            handler(newDataDetailReturns) {
                this.listDetailReturns = newDataDetailReturns;
            },
            immediate: true
        },
        isShow: {
            handler: function (valShow) {
                this.configModalDetailsReturns.model = valShow;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
    white-space: nowrap;
}
</style>
