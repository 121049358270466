<template>
    <div>
        <!-- <div class="form-group row">
            <label class="col-sm-3">GA有無（LP）</label>
            <div class="col-sm-9">
                {{ dataForm.give_away == 0 ? 'NO' : 'YES' }}
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">GAからの取得情報<br />（LPからの）⇒</label>
            <div class="col-sm-9"></div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">流入先</label>
            <div class="col-sm-9">
                {{ dataForm.give_away_information?.inflow_destination ?? '' }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">流入率</label>
            <div class="col-sm-9">
                {{ dataForm.give_away_information?.inflow_rate ?? '' }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">離脱率</label>
            <div class="col-sm-9">
                {{ dataForm.give_away_information?.attrition_rate ?? '' }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">ATELU（SNS収集ツール）<br />レポート格納</label>
            <div class="col-sm-9">
                <div class="row mb-1" v-for="(item, k) in dataForm.campaign_sns_collections_tools" :key="k + 'sns' + item.id">
                    <div class="col-sm-9 align-items-center">
                        <a target="_blank" :href="item.path">{{ item.origin_name }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">キャッシュバックの応募者不備件数/当選者に対しての％</label>
            <div class="col-sm-9">
                {{ dataForm.defects_in_cash_back_applicants_rate }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">SNS当選者フォーム登録なし件数/当選者に対しての％</label>
            <div class="col-sm-9">
                {{ dataForm.sns_winners_without_registration_rate }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">LINE友達増減数</label>
            <div class="col-sm-9">
                {{ dataForm.line_friends_growth }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告媒体</label>
            <div class="col-sm-9">
                {{ dataForm.advertising_media }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告費</label>
            <div class="col-sm-9">
                {{ dataForm.advertising_cost }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告出稿による結果</label>
            <div class="col-sm-9">
                {{ dataForm.advertising_result }}
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"
                >SNSのフォロワー増加数<br />
                <small>※SNSの時に分かれば入力</small></label
            >
            <div class="col-sm-9">
                {{ dataForm.number_of_sns_followers }}
            </div>
        </div> -->

        <!-- total_number_of_applications -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.numberOfApplications') }}</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.totalNumberOfApplications') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.total_number_of_applications }}
                    </div>
                </div>

                <!-- number_of_applications -->
                <div v-for="(itemApplycation, idx) in dataForm.number_of_applications" :key="`scope-${idx}`" class="form-group row">
                    <label class="col-sm-2">{{ itemApplycation?.master?.value ?? '' }}</label>
                    <div class="col-sm-9">
                        {{ itemApplycation?.quantity ?? 0 }}
                    </div>
                </div>
            </div>
        </div>

        <!-- number_of_inquiries -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.numberOfInquiries') }}</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.totalnumberOfInquiries') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.total_number_of_inquiries }}
                    </div>
                </div>
                <div v-for="(itemScope, idx) in dataForm.number_of_inquiries" :key="`scope-${idx}`" class="form-group row">
                    <label class="col-sm-2">{{ itemScope?.master_order_scope?.value ?? '' }}</label>
                    <div class="col-sm-9">
                        {{ itemScope.quantity }}
                    </div>
                </div>
            </div>
        </div>

        <!-- platform_followers_number -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.SNSAccount') }}</label>
            <div class="col-sm-9">
                <div class="table-responsive" style="max-height: 400px; overflow-y: auto">
                    <table class="table table-bordered custom__table--sticky table-box">
                        <thead>
                            <tr>
                                <th v-for="(item, k) in configTableSNS.headers" :key="k">
                                    <div class="d-flex align-items-center">
                                        {{ item.label }}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(itemTb, idx) in dataForm.platform_followers_number" :key="idx">
                                <td>
                                    <div class="row-data-small">
                                        {{ itemTb?.master?.value ?? '' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        {{ itemTb?.initial_quantity ?? 0 }}
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        {{ itemTb?.final_quantity ?? 0 }}
                                    </div>
                                </td>
                                <td>
                                    <div class="row-data-small">
                                        {{ itemTb?.increase_quantity ?? 0 }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- advertising_activity -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.advertisingRelated') }}</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.advertisingOperation') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.advertising_activity?.value ?? '' }}
                    </div>
                </div>
            </div>
        </div>

        <!-- social_platforms -->
        <div v-if="dataForm.advertising_activity?.value == 'あり（自社）' || dataForm.advertising_activity?.value == 'あり（他社）'">
            <div class="form-group row">
                <label class="col-sm-3"></label>
                <div class="col-sm-8">
                    <div class="form-group row">
                        <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.mediaCosts') }}</label>
                        <div class="col-sm-9">
                            <div class="d-flex flex-wrap">
                                {{ dataForm.social_platforms.map((item) => item.master.value).join('、') ?? '' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3"></label>
                <div class="col-sm-8">
                    <div v-for="(media, idx) in dataForm.social_platforms" :key="`Media-${idx}`" class="form-group row">
                        <label class="col-sm-2" style="white-space: nowrap">{{ media?.master?.value }}</label>
                        <div class="col-sm-9" v-html="textLineBreaks(media.note.detail)"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- give_away_related -->
        <div>
            <div class="form-group row">
                <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.GArelated') }}</label>
                <div class="col-sm-8">
                    <div class="form-group row">
                        <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.embedTags') }}</label>
                        <div class="col-sm-9">
                            {{ dataForm.give_away_related?.value ?? '' }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- account_infor_on_screen -->
            <div v-if="dataForm.give_away_related?.value == 'あり'" class="form-group row">
                <label class="col-sm-3"></label>
                <div class="col-sm-8">
                    <div class="form-group row">
                        <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.managementScreen') }}</label>
                        <div class="col-sm-9">
                            <div class="col-sm-9" v-html="textLineBreaks(dataForm.account_infor_on_screen)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- pdca_file_report -->
        <div class="form-group row">
            <label class="col-sm-3">{{ this.$t('pageCampaign.tab7.report') }}</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.PDCAReportStorage') }}</label>
                    <div class="col-sm-9">
                        <div v-if="dataForm.pdca_file_report?.origin_name" class="row mb-1">
                            <div class="col-sm-9 align-items-center">
                                <a target="_blank" :href="dataForm.pdca_file_report?.path">{{ dataForm.pdca_file_report?.origin_name ?? '' }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- cashback_file_report -->
        <div class="form-group row">
            <label class="col-sm-3"></label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-2">{{ this.$t('pageCampaign.tab7.cashBack') }}</label>
                    <div class="col-sm-9">
                        <div v-if="dataForm.cashback_file_report?.origin_name" class="row mb-1">
                            <div class="col-sm-9 align-items-center">
                                <a target="_blank" :href="dataForm.cashback_file_report?.path">{{
                                    dataForm.cashback_file_report?.origin_name ?? ''
                                }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CONFIG_TABLE_SNS } from '@/components/Campaign/Tab7/constants';
import { renderLineBreaks } from '@/utils/format';

export default {
    name: 'TabResultRegistration',
    props: {
        dataForm: Object,
        default: () => {}
    },
    data() {
        return {
            configTableSNS: CONFIG_TABLE_SNS
        };
    },
    methods: {
        textLineBreaks(text) {
            return renderLineBreaks(text);
        }
    }
};
</script>

<style lang="scss" scoped></style>
