<template>
    <div>
        <h4 class="font-weight-bold mb-4">
            {{ $t('settingPhone.inforBasic') }}
        </h4>
        <!-- キャンペーン名 -->
        <div class="form-group row">
            <label class="col-sm-3" for="nameCampaign">{{ $t('settingPhone.nameCampaign') }} <span class="text-danger">*</span></label>
            <div class="col-sm-9">
                <InputText
                    id="nameCampaign"
                    :model.sync="initForm.campaign.name"
                    :config="{
                        error: initForm.configSelectCampaign.error,
                        errorCustomMess: initForm.errorCampaign
                    }"
                    :disabled="true"
                />
            </div>
        </div>

        <!-- personInChargePartner  営業担当者-->
        <div class="form-group row">
            <label class="col-sm-3" for="personInChargePartner">{{ $t('settingPhone.personInChargePartner') }} </label>
            <div class="col-sm-9">
                <InputText
                    id="personInChargePartner"
                    :model.sync="initForm.sale_staff"
                    :config="{
                        error: false
                    }"
                    disabled
                />
            </div>
        </div>

        <!-- type excute workflow 対応先 -->
        <div class="form-group row">
            <label class="col-sm-3" for="workflow-type-common">{{ $t('settingPhone.correspondenceDestination') }} </label>
            <div class="col-sm-9">
                <MultiSelectCommon
                    :id="`select-workflow-type-common`"
                    :value.sync="initForm.workflow_type"
                    :options="initForm.optionsWorkflowTypeCommon"
                    :config.sync="initForm.configWorkflowTypeCommon"
                    :class="{
                        disabled_cursor: disabledField.noEditTypeWorkflow
                    }"
                    :disabled="disabledField.noEditTypeWorkflow"
                    @close="
                        () => {
                            initForm.partner = null;
                        }
                    "
                />
                <div v-if="initForm.configWorkflowTypeCommon.error" class="invalid-feedback d-block">
                    <span>{{ initForm.configWorkflowTypeCommon.errorText }}</span>
                </div>
            </div>
        </div>

        <!-- correspondingPerson 対応者 -->
        <div v-if="initForm.workflow_type?.id === workflowType.PARTNER" class="form-group row">
            <label class="col-sm-3" for="correspondingPerson"
                >{{ $t('settingPhone.correspondingPerson') }}
                <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
                <MultiSelectCommon
                    :id="`select-partner-setting-phone`"
                    :value.sync="initForm.partner"
                    :options="initForm.optionsPartnerSettingPhone"
                    :config.sync="initForm.configPartner"
                    :disabled="disabled"
                    @open="handleOpenPartner"
                />
                <div v-if="initForm.configPartner.error" class="invalid-feedback">
                    <span>{{ initForm.configPartner.errorPartner }}</span>
                </div>
            </div>
        </div>

        <!-- timePhoneOpenSetting 電話事務局 開設期間 -->
        <div class="form-group row">
            <label class="col-sm-3" for="timePhoneOpenSetting">{{ $t('settingPhone.timePhoneOpenSetting') }} </label>
            <div class="col-sm-9 mb-3">
                <DateTimeCommon
                    id="select-campaign-start-date-time"
                    class="mb-3"
                    :full_date.sync="initForm.phone_setting_start_date"
                    :full_time.sync="initForm.phone_setting_start_time"
                    :config.sync="initForm.configPhoneSettingStartDate"
                    :disabled="true"
                >
                    <span v-if="initForm.configPhoneSettingStartDate.error" class="invalid-feedback d-block">{{
                        initForm.errorPhoneSettingStartDate
                    }}</span>
                </DateTimeCommon>

                <DateTimeCommon
                    id="select-campaign-end-date-time"
                    :full_date.sync="initForm.phone_setting_end_date"
                    :full_time.sync="initForm.phone_setting_end_time"
                    :config.sync="initForm.configPhoneSettingEndDate"
                    :disabled="true"
                >
                    <span v-if="initForm.configPhoneSettingEndDate.error" class="invalid-feedback d-block">{{
                        initForm.errorPhoneSettingEndDate
                    }}</span>
                </DateTimeCommon>
            </div>
        </div>

        <!-- typePhoneNumber 電話番号種別 -->
        <div class="form-group row">
            <label class="col-sm-3" for="typePhoneNumber">{{ $t('settingPhone.typePhoneNumber') }} </label>
            <div class="col-sm-9">
                <MultiSelectCommon
                    :id="`multi-select-campaign`"
                    :value.sync="initForm.phone_number_type"
                    :options="phoneNumberType"
                    :config.sync="initForm.configPhoneNumberType"
                    :disabled="true"
                />
            </div>
        </div>

        <!-- 予約/03番号 -->
        <div v-if="hasPreOrderPhoneNumber" class="form-group row">
            <label class="col-sm-3" for="pickBefore" style="min-width: fit-content">{{ $t('settingPhone.pickBefore') }} </label>
            <div class="col-sm-9">
                <InputText
                    id="pickBefore"
                    :model.sync="initForm.pre_order_phone_numbers"
                    :config="{
                        isNumber: true,
                        maxlength: 15
                    }"
                    :disabled="disabledField.stepSuccessDisabledForUserCP || disabledField.testCall"
                />
            </div>
        </div>

        <!-- 予約0120番号 -->
        <div v-if="hasReservation" class="form-group row">
            <label class="col-sm-3" for="picked0120" style="min-width: fit-content">{{ $t('settingPhone.picked0120') }} </label>
            <div class="col-sm-9">
                <InputText
                    id="picked0120"
                    :model.sync="initForm.reservation"
                    :config="{
                        isNumber: true,
                        maxlength: 15
                    }"
                    :disabled="disabledField.stepSuccessDisabledForUserCP || disabledField.testCall"
                />
            </div>
        </div>

        <p class="font-weight-bold p-2 rounded" style="background-color: #e8e8e8">{{ $t('settingPhone.open') }}</p>
        <!-- note 1,2,3 -->
        <div>
            <p class="text-danger">
                {{ $t('settingPhone.note1') }}
            </p>
            <p class="text-danger">
                {{ $t('settingPhone.note2') }}
            </p>
            <p class="text-danger">
                {{ $t('settingPhone.note3') }}
            </p>
        </div>

        <!--prevPick 工事予定 -->
        <div class="form-group row flex-nowrap">
            <label class="col-sm-3" for="prevPick" style="min-width: fit-content">{{ $t('settingPhone.prevPick') }} </label>
            <div class="col-sm-8">
                <InputText
                    id="prevPick"
                    :model.sync="initForm.planning_to_set_up"
                    :config="{
                        error: false
                    }"
                    :disabled="disabledField.stepSuccessDisabledForUserCP"
                />
            </div>
        </div>

        <!-- start desired_release_date -->
        <div>
            <div>
                <!-- desiredReleaseDate 電話番号発行希望日 -->
                <div class="form-group row mb-4">
                    <label class="col-sm-3" for="desiredReleaseDate">{{ $t('settingPhone.desiredReleaseDate') }} </label>
                    <div class="col-sm-8">
                        <DateTimeCommon
                            id="select-desired_release_date_time"
                            :full_date.sync="initForm.desired_release_date"
                            :full_time.sync="initForm.desired_release_date_time"
                            :config.sync="initForm.configDesiredReleaseDate"
                            :disabled="true"
                        >
                            <span v-if="initForm.configDesiredReleaseDate.error" class="invalid-feedback d-block">{{
                                initForm.errorDesiredReleaseDate
                            }}</span>
                        </DateTimeCommon>
                    </div>
                </div>
                <!-- note 4,5 -->
                <div>
                    <p class="text-danger">
                        {{ $t('settingPhone.note4') }}
                    </p>
                    <p class="text-danger">
                        {{ $t('settingPhone.note5') }}
                    </p>
                </div>

                <!-- desiredOpenPhoneDate  電話開設希望日 -->
                <div class="form-group row mb-4">
                    <label class="col-sm-3" style="padding-bottom: 22px" for="desiredOpenPhoneDate"
                        >{{ $t('settingPhone.desiredOpenPhoneDate') }}
                    </label>
                    <div class="col-sm-8">
                        <DatePickerCommon
                            :full_date.sync="initForm.desired_open_phone_date"
                            :config.sync="initForm.configDesiredOpenPhoneDate"
                            :disabled="disabled"
                        >
                            <div v-if="initForm.configDesiredOpenPhoneDate.error" class="invalid-feedback d-block">
                                <span>{{ initForm.errorDesiredOpenPhoneDate }}</span>
                            </div>
                        </DatePickerCommon>
                    </div>
                </div>
                <!-- note 6, 7, 8 -->
                <div>
                    <p class="text-danger">
                        {{ $t('settingPhone.note6') }}
                    </p>
                </div>
            </div>
        </div>
        <!-- end desired_release_date -->
        <p class="font-weight-bold p-2 rounded" style="background-color: #e8e8e8">{{ $t('settingPhone.close') }}</p>
        <!-- desiredDateToEndTheCall 電話終了希望日 -->
        <div class="form-group row">
            <label class="col-sm-3" for="desiredDateToEndTheCall">{{ $t('settingPhone.desiredDateToEndTheCall') }} </label>
            <div class="col-sm-9">
                <DatePickerCommon
                    :full_date.sync="initForm.desired_date_to_end_the_call"
                    :config.sync="initForm.configDesiredDateToEndTheCall"
                    class="mb-3"
                    :disabled="disabled"
                >
                    <div v-if="initForm.configDesiredDateToEndTheCall.error" class="invalid-feedback d-block">
                        <span>{{ initForm.configDesiredDateToEndTheCall.errorDesiredDateToEndTheCall }}</span>
                    </div>
                </DatePickerCommon>
            </div>
        </div>

        <!-- settingDetails 設定内容 -->
        <div class="form-group row">
            <label class="col-sm-3" for="settingDetails">{{ $t('settingPhone.settingDetails') }} </label>
            <div class="col-sm-9">
                <MultiSelectCommon
                    id="settingDetails"
                    :value.sync="initForm.setting_content_type"
                    :options="settingContentTypes"
                    :config.sync="initForm.configSettingContentType"
                    :disabled="disabled"
                    @select="
                        () => {
                            initForm.setting_content_data = '';
                        }
                    "
                    @remove="
                        () => {
                            initForm.setting_content_data = '';
                        }
                    "
                />
            </div>
        </div>

        <div v-if="initForm.setting_content_type?.id && initForm.setting_content_type.id == 3" class="form-group row">
            <label class="col-sm-3" for="settingContentData">{{ $t('settingPhone.settingContentData') }} </label>
            <div class="col-sm-9">
                <InputText
                    id="settingContentData"
                    :model.sync="initForm.setting_content_data"
                    :config="{
                        error: false
                    }"
                    :disabled="disabled"
                />
            </div>
        </div>

        <!-- note 9, 10 -->
        <div>
            <p class="text-danger">
                {{ $t('settingPhone.note9') }}
            </p>
            <p class="text-danger">
                {{ $t('settingPhone.note10') }}
            </p>
        </div>
    </div>
</template>

<script>
import MultiSelectCommon from '@/components/Select/multi/main.vue';
import DatePickerCommon from '@/components/Select/date/main.vue';
import DateTimeCommon from '@/components/Select/date-time/main.vue';
import { PHONE_NUMBER_TYPE, WORKFLOW_TYPE, SETTING_CONTENT_TYPES } from '@/views/pages/documents/settingPhone/constants';
import { getListPartnersSettingPhone } from '@/views/pages/documents/settingPhone/index';
import { InputText } from '@/components/Input';

export default {
    name: 'StartOne',
    components: {
        MultiSelectCommon,
        DatePickerCommon,
        DateTimeCommon,
        InputText
    },
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disabledField: {
            type: Object,
            default: () => {}
        },
        isSeenFirst: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            initForm: null,
            phoneNumberType: PHONE_NUMBER_TYPE,
            idSelectComponent: null,
            workflowType: WORKFLOW_TYPE,
            settingContentTypes: SETTING_CONTENT_TYPES,
            hasPreOrderPhoneNumber: false,
            hasReservation: false
        };
    },
    methods: {
        async handleOpenPartner(idSelectComponent) {
            if (this.idSelectComponent === idSelectComponent) return;
            this.initForm.configPartner.loading = true;
            this.idSelectComponent = idSelectComponent;
            this.initForm.optionsPartnerSettingPhone = await getListPartnersSettingPhone();
            this.initForm.configPartner.loading = false;
        },

        handleCheckConditionTel(campaign_master_order_scopes) {
            if (campaign_master_order_scopes && campaign_master_order_scopes.length > 0) {
                let getDetails = [];
                campaign_master_order_scopes.forEach((largeItem) => {
                    largeItem.medium_items.forEach((mediumItem) => {
                        mediumItem.small_items.forEach((smallItem) => {
                            getDetails.push(`${smallItem.small_item.value}`);
                        });
                    });
                });
                if (getDetails.includes('03番号')) {
                    this.initForm.phone_number_type = PHONE_NUMBER_TYPE[0];
                    this.hasPreOrderPhoneNumber = true;
                } else if (getDetails.includes('0120番号')) {
                    this.initForm.phone_number_type = PHONE_NUMBER_TYPE[1];
                    this.hasReservation = true;
                } else {
                    this.initForm.phone_number_type = null;
                }
            }
        }
    },
    watch: {
        data: {
            handler(valReceived) {
                this.initForm = valReceived;
                this.handleCheckConditionTel(this.initForm.campaign_master_order_scopes);
            },
            immediate: true
        },
        initForm: {
            handler(newDataInitForm) {
                this.$emit('updateData', newDataInitForm);
            },
            deep: true
        },
        isSeenFirst: {
            handler(val) {
                if (val) {
                    this.$emit('updateData', this.initForm);
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
