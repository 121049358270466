import { tranferShortCode } from '@/utils/format';

function setDataInternal(obj) {
    const replacements = {
        キャンペーン名: obj.shortCode.name_campaign ?? '',
        'エンド/代理店名': obj.shortCode.name_client_and_agency ?? '',
        キャンペーン開始: `${obj.shortCode.campaign_start_date ?? ''} ${obj.shortCode.campaign_start_time ?? ''}`,
        キャンペーン終了: `${obj.shortCode.campaign_end_date ?? ''} ${obj.shortCode.campaign_end_time ?? ''}`,
        事務局開始: `${obj.shortCode.secretariat_start_date ?? ''} ${obj.shortCode.secretariat_start_time ?? ''}`,
        事務局終了: `${obj.shortCode.secretariat_end_date ?? ''} ${obj.shortCode.secretariat_end_time ?? ''}`,
        分かれば: obj.shortCode.total_number_of_win_prize ?? '',
        メイン担当: obj.shortCode.person_in_charge ?? '',
        営業担当: obj.shortCode.sale_staff ?? '',
        電話のURL: obj.shortCode.url_campaign ?? '',
        ページのURL: obj.shortCode.url_phone_setting ?? '',
        番号形態: obj.shortCode.phone_number_type ?? '',
        電話の設定_電話番号: obj.shortCode.pre_order_phone_numbers
            ? obj.shortCode.pre_order_phone_numbers
            : obj.shortCode.reservation
              ? obj.shortCode.reservation
              : '',
        番号発行希望日: obj.shortCode.desired_release_date ?? '',
        応募形態: obj.shortCode.categories ?? '',
        当選者数: obj.shortCode.total_number_of_win_prize ?? '',
        対応先: obj.shortCode.name_workflow ?? ''
    };
    switch (obj.child_id) {
        case 1:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 2:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 3:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 4:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 5:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 6:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 7:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 8:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 9:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 10:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 11:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 12:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 13:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 14:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 15:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 16:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        default:
            break;
    }
    return obj;
}

function setDataPartner(obj) {
    const replacements = {
        キャンペーン名: obj.shortCode.name_campaign ?? '',
        'エンド/代理店名': obj.shortCode.name_client_and_agency ?? '',
        キャンペーン開始: `${obj.shortCode.campaign_start_date ?? ''} ${obj.shortCode.campaign_start_time}`,
        キャンペーン終了: `${obj.shortCode.campaign_end_date ?? ''} ${obj.shortCode.campaign_end_time}`,
        事務局開始: `${obj.shortCode.secretariat_start_date ?? ''} ${obj.shortCode.secretariat_start_time}`,
        事務局終了: `${obj.shortCode.secretariat_end_date ?? ''} ${obj.shortCode.secretariat_end_time}`,
        分かれば: obj.shortCode.total_number_of_win_prize ?? '',
        メイン担当: obj.shortCode.person_in_charge ?? '',
        営業担当: obj.shortCode.sale_staff ?? '',
        電話のURL: obj.shortCode.url_campaign ?? '',
        ページのURL: obj.shortCode.url_phone_setting ?? '',
        番号形態: obj.shortCode.phone_number_type ?? '',
        電話の設定_電話番号: obj.shortCode.pre_order_phone_numbers
            ? obj.shortCode.pre_order_phone_numbers
            : obj.shortCode.reservation
              ? obj.shortCode.reservation
              : '',
        番号発行希望日: obj.shortCode.desired_release_date ?? '',
        応募形態: obj.shortCode.categories ?? '',
        当選者数: obj.shortCode.total_number_of_win_prize ?? '',
        対応先: obj.shortCode.name_workflow ?? ''
    };

    switch (obj.child_id) {
        case 1:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 2:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 3:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 4:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 5:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 6:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 7:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 8:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 9:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 10:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 11:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 12:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 13:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 14:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        case 15:
            obj.messages.content = tranferShortCode(obj.details.message ?? '', replacements);
            obj.task.content = tranferShortCode(obj.details.task ?? '', replacements);
            break;
        default:
            break;
    }
    return obj;
}

export { setDataInternal, setDataPartner };
